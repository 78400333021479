// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-branding-tsx": () => import("./../../../src/pages/branding.tsx" /* webpackChunkName: "component---src-pages-branding-tsx" */),
  "component---src-pages-dev-bug-workbench-tsx": () => import("./../../../src/pages/dev/bug-workbench.tsx" /* webpackChunkName: "component---src-pages-dev-bug-workbench-tsx" */),
  "component---src-pages-dev-playground-plugins-tsx": () => import("./../../../src/pages/dev/playground-plugins.tsx" /* webpackChunkName: "component---src-pages-dev-playground-plugins-tsx" */),
  "component---src-pages-dev-sandbox-tsx": () => import("./../../../src/pages/dev/sandbox.tsx" /* webpackChunkName: "component---src-pages-dev-sandbox-tsx" */),
  "component---src-pages-dev-twoslash-tsx": () => import("./../../../src/pages/dev/twoslash.tsx" /* webpackChunkName: "component---src-pages-dev-twoslash-tsx" */),
  "component---src-pages-dev-typescript-vfs-tsx": () => import("./../../../src/pages/dev/typescript-vfs.tsx" /* webpackChunkName: "component---src-pages-dev-typescript-vfs-tsx" */),
  "component---src-templates-documentation-tsx": () => import("./../../../src/templates/documentation.tsx" /* webpackChunkName: "component---src-templates-documentation-tsx" */),
  "component---src-templates-glossary-tsx": () => import("./../../../src/templates/glossary.tsx" /* webpackChunkName: "component---src-templates-glossary-tsx" */),
  "component---src-templates-pages-cheatsheets-tsx": () => import("./../../../src/templates/pages/cheatsheets.tsx" /* webpackChunkName: "component---src-templates-pages-cheatsheets-tsx" */),
  "component---src-templates-pages-community-tsx": () => import("./../../../src/templates/pages/community.tsx" /* webpackChunkName: "component---src-templates-pages-community-tsx" */),
  "component---src-templates-pages-docs-bootstrap-tsx": () => import("./../../../src/templates/pages/docs/bootstrap.tsx" /* webpackChunkName: "component---src-templates-pages-docs-bootstrap-tsx" */),
  "component---src-templates-pages-docs-handbook-index-tsx": () => import("./../../../src/templates/pages/docs/handbook/index.tsx" /* webpackChunkName: "component---src-templates-pages-docs-handbook-index-tsx" */),
  "component---src-templates-pages-docs-index-tsx": () => import("./../../../src/templates/pages/docs/index.tsx" /* webpackChunkName: "component---src-templates-pages-docs-index-tsx" */),
  "component---src-templates-pages-download-tsx": () => import("./../../../src/templates/pages/download.tsx" /* webpackChunkName: "component---src-templates-pages-download-tsx" */),
  "component---src-templates-pages-empty-tsx": () => import("./../../../src/templates/pages/empty.tsx" /* webpackChunkName: "component---src-templates-pages-empty-tsx" */),
  "component---src-templates-pages-index-tsx": () => import("./../../../src/templates/pages/index.tsx" /* webpackChunkName: "component---src-templates-pages-index-tsx" */),
  "component---src-templates-pages-tools-tsx": () => import("./../../../src/templates/pages/tools.tsx" /* webpackChunkName: "component---src-templates-pages-tools-tsx" */),
  "component---src-templates-pages-why-create-typescript-tsx": () => import("./../../../src/templates/pages/why-create-typescript.tsx" /* webpackChunkName: "component---src-templates-pages-why-create-typescript-tsx" */),
  "component---src-templates-play-example-tsx": () => import("./../../../src/templates/play-example.tsx" /* webpackChunkName: "component---src-templates-play-example-tsx" */),
  "component---src-templates-play-tsx": () => import("./../../../src/templates/play.tsx" /* webpackChunkName: "component---src-templates-play-tsx" */),
  "component---src-templates-playground-handbook-tsx": () => import("./../../../src/templates/playgroundHandbook.tsx" /* webpackChunkName: "component---src-templates-playground-handbook-tsx" */),
  "component---src-templates-tsconfig-option-one-page-tsx": () => import("./../../../src/templates/tsconfigOptionOnePage.tsx" /* webpackChunkName: "component---src-templates-tsconfig-option-one-page-tsx" */),
  "component---src-templates-tsconfig-reference-tsx": () => import("./../../../src/templates/tsconfigReference.tsx" /* webpackChunkName: "component---src-templates-tsconfig-reference-tsx" */)
}

